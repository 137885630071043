// NeedAuth.js
import { connect } from 'react-redux';
import getMeData from '../selector/getMeData.js';
import digestJwtToken from '../action/digestJwtToken.js';
import fetchAccessToken, { viaTypes } from '../action/fetchAccessToken.js';
import NeedAuth from '../component/NeedAuth.jsx';

const mapStateToProps = state => {
  return {
    isAuthed: !!getMeData(state, 'id'),
    token: getMeData(state, 'token'),
    refreshToken: getMeData(state, 'refreshToken'),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    digestJwtToken: ({ token }) => dispatch(digestJwtToken({ token })),
    fetchAccessToken: ({ refreshToken }) =>
      dispatch(
        fetchAccessToken({ refreshToken, via: viaTypes.GIVEN_REFRESH_TOKEN })
      ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NeedAuth);
